<template>
  <div>
    <div class="page__head d-flex">
      <div
        class="page__head__container ml-auto align-items-center justify-content-between"
      >
        <div class="page__head__text flex-fill"></div>
        <div class="page__head__actions">
          <div class="d-flex">
            <button
              type="button"
              class="mx-2 button page__body__header__button button--grey text--capital"
              data-target="#newStateModal"
              data-toggle="modal"
              v-if="userCanCreateState"
            >
              <span class="d-inline-flex mr-2">new state</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="page__body">
      <div class="page__body__header">
        <div
          class="page__head__container align-items-center justify-content-between"
        >
          <div class="page__head__text flex-fill">
            <h1 class="page__head__title">States</h1>
          </div>
        </div>
      </div>

      <div class="row" style="padding-left: 14px">
        <table class="customtable w-100">
          <thead>
            <tr>
              <th
                scope="col"
                class="table__header__text table__text text--capital"
                style="width: 85%"
              >
                name
              </th>
              <th
                scope="col"
                class="table__header__text table__text text--capital"
                style="width: 15%"
              >
                actions
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in states" v-bind:key="item.stateKey">
              <td>
                <span class="table__body__text table__text text--capital">{{
                  item.name
                }}</span>
              </td>
              <td>
                <div class="align-self-center dropdown">
                  <button
                    class="button button--grey text--capital text--small d-flex align-items-center"
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    action
                    <span class="dropdown__icon">
                      <ion-icon name="caret-down-outline"></ion-icon>
                    </span>
                  </button>
                  <div
                    class="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <a
                      class="dropdown-item text--capital"
                      data-toggle="modal"
                      data-target="#editStateModal"
                      v-on:click="editState(item.stateKey)"
                      v-if="userCanEditState"
                      >edit</a
                    >
                    <a
                      class="dropdown-item text--capital"
                      data-toggle="modal"
                      data-target="#deleteConfirmModal"
                      v-on:click="openDeleteConfirmation(item)"
                      v-if="userCanDeleteState"
                      >delete</a
                    >
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="pagination w-100">
          <div class="d-flex align-items-center justify-content-between w-100">
            <div class="text--capital text--regular pagination__text">
              showing
              <span
                class="d-inline-block text--primary"
                style="padding-right: 15px"
              >
                <select @change="changeLength" v-model="fetchModel.pageSize">
                  <option :value="10">10</option>
                  <option :value="20">20</option>
                  <option :value="30">30</option>
                  <option :value="50">50</option>
                  <option :value="100">100</option>
                </select>
              </span>
              <span
                class="d-inline-block text--primary"
                v-if="paging.totalCount == 0"
                >0</span
              >
              <span class="d-inline-block text--primary" v-else>{{
                !paging.hasPreviousPage
                  ? paging.pageIndex
                  : paging.pageSize * (paging.pageIndex - 1) + 1
              }}</span>
              to
              <span class="d-inline-block text--primary">{{
                paging.hasNextPage
                  ? paging.pageSize * paging.pageIndex
                  : paging.totalCount
              }}</span>
              of
              <span>{{ paging.totalCount }}</span>
            </div>
            <div
              class="pagination__buttons d-flex align-items-center justify-content-between"
            >
              <button
                class="button button--sm text--regular"
                v-if="paging.hasPreviousPage"
                @click="stepPage('previous')"
              >
                <ion-icon name="chevron-back-outline"></ion-icon>
              </button>
              <button
                class="button text--regular button--sm ml-2"
                v-for="num in paging.totalPages"
                :key="num"
                :class="{
                  'button--primary text--white': paging.pageIndex === num,
                }"
                :disabled="paging.pageIndex === num"
                @click="changePage(num)"
              >
                {{ num }}
              </button>

              <button
                class="button text--regular button--sm ml-2"
                v-if="paging.hasNextPage"
                @click="stepPage('next')"
              >
                <ion-icon name="chevron-forward-outline"></ion-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modals -->
    <div
      class="modal modal__right"
      id="newStateModal"
      tabindex=""
      role="dialog"
      aria-labelledby="modalLabel"
      aria-hidden="true"
    >
      <div class="modal__dialog" role="document">
        <div class="modal__content">
          <div class="modal__head">
            <div class="modal__head__icon" v-show="!isSaving">
              <a
                data-dismiss="modal"
                id="newStateModalClose"
                class="modal__close modal__close--black"
              >
                <ion-icon name="arrow-back-outline"></ion-icon>
              </a>
            </div>
            <h5 class="modal__head__title text--capital">Add a State</h5>
          </div>
          <div class="modal__body">
            <form class="form" @submit="saveStateForm">
              <div class="form-row">
                <div class="col-md-6 form__item">
                  <div class="form-group">
                    <label class="form__label">Name</label>
                    <input
                      class="input form__input w-100"
                      type="text"
                      v-model="stateForm.name"
                      v-bind:class="{
                        'input-required': stateFormErr.name,
                      }"
                    />
                  </div>
                </div>
              </div>
              <div class="form__item">
                <div class="form__action">
                  <button
                    type="submit"
                    class="button form__button form__button--lg w-30 save-changes"
                    v-if="!isSaving"
                  >
                    Save State
                  </button>

                  <a
                    role="button"
                    class="button form__button form__button--lg w-25 save-changes"
                    v-if="isSaving"
                    :disabled="true"
                  >
                    <div class="spinner-border text-light" role="status"></div>
                  </a>
                  <a
                    data-dismiss="modal"
                    role="button"
                    class="modal__close form__action__text form__action__text--danger text--capital ml6"
                    v-if="!isSaving"
                  >
                    cancel
                  </a>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal modal__right"
      id="editStateModal"
      tabindex=""
      role="dialog"
      aria-labelledby="modalLabel"
      aria-hidden="true"
    >
      <div class="modal__dialog" role="document">
        <div class="modal__content">
          <div class="modal__head">
            <div class="modal__head__icon" v-show="!isSaving">
              <a
                data-dismiss="modal"
                id="editStateModalClose"
                class="modal__close modal__close--black"
              >
                <ion-icon name="arrow-back-outline"></ion-icon>
              </a>
            </div>
            <h5 class="modal__head__title text--capital">Edit a State</h5>
          </div>
          <div class="modal__body">
            <form class="form" @submit="saveStateEditForm">
              <div class="form-row">
                <div class="col-md-6 form__item">
                  <div class="form-group">
                    <label class="form__label">Name</label>
                    <input
                      class="input form__input w-100"
                      type="text"
                      v-model="stateEditForm.name"
                      v-bind:class="{
                        'input-required': stateEditFormErr.name,
                      }"
                    />
                  </div>
                </div>
              </div>
              <div class="form__item">
                <div class="form__action">
                  <button
                    type="submit"
                    class="button form__button form__button--lg w-30 save-changes"
                    v-if="!isSaving"
                  >
                    Save State
                  </button>

                  <a
                    role="button"
                    class="button form__button form__button--lg w-25 save-changes"
                    v-if="isSaving"
                    :disabled="true"
                  >
                    <div class="spinner-border text-light" role="status"></div>
                  </a>
                  <a
                    data-dismiss="modal"
                    role="button"
                    class="modal__close form__action__text form__action__text--danger text--capital ml6"
                    v-if="!isSaving"
                  >
                    cancel
                  </a>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal modal__confirm"
      id="deleteConfirmModal"
      aria-hidden="true"
    >
      <ion-icon
        class="modal__head__icon modal__close"
        name="close-outline"
        data-dismiss="modal"
        aria-label="Close"
      ></ion-icon>
      <div class="">
        <div class="modal__dialog" role="document" v-if="stateToDelete != null">
          <div class="modal__head">
            <div
              class="modal__close modal__head__icon modal__close--white"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span> <ion-icon name="close-outline"></ion-icon> Close</span>
            </div>

            <h5 class="modal__header__title">Confirmation</h5>
          </div>
          <div class="modal__content">
            <div class="modal__header">
              <div class="modal__header__title">
                <h5 class="modal__head__title">
                  Are you sure you want to delete this state
                  {{ stateToDelete.name }} ?
                </h5>
              </div>
              <div class="modal__header__subtitle">
                <h6 class="my-4">
                  When you delete this state from your list, you won't be able
                  to access it again,
                </h6>
              </div>
            </div>

            <div>
              <button
                class="button form__button form__button--lg mr-5 modal__confirm__button modal__confirm__button--cancel"
                data-dismiss="modal"
                aria-label="Close"
                id="deleteCloseButton"
              >
                Cancel
              </button>

              <!-- loader button -->
              <a
                role="button"
                class="button form__button form__button--lg w-25 modal__confirm__button modal__confirm__button--delete"
                v-if="isDeleting"
                :disabled="true"
              >
                <div class="spinner-border text-light" role="status"></div>
              </a>
              <button
                class="button form__button form__button--lg modal__confirm__button modal__confirm__button--delete"
                type="submit"
                v-if="!isDeleting"
                v-on:click="deleteState()"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--ALERT NOTIFICATION-->
    <div class="toaster show">
      <div
        class="toaster__container"
        v-if="alert.show"
        v-bind:class="{
          'toaster--success': alert.status === 'success',
          'toaster--error': alert.status === 'fail',
          'toaster--warn': alert.status === 'warn',
        }"
      >
        <div class="text-right">
          <span class="fa fa-times" @click="alert.show = false"></span>
        </div>
        <div>
          <span class="d-block text--small text--600 text--white">{{
            alert.title
          }}</span>
          <span class="d-block text--tiny text--400 text--white">{{
            alert.description
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Role } from "@/core/services/role.js";
import { mapState } from "vuex";
import stateService from "@/core/services/general-setup-service/state.service";
import moment from "moment";
export default {
  name: "state",
  data: function () {
    return {
      isLoading: false,
      isSaving: false,
      isDeleting: false,
      alert: {
        show: false,
        status: "Success",
        title: "Success",
        description: "",
      },
      // State
      states: [],
      stateToDelete: null,
      stateForm: {
        name: null,
      },
      stateFormErr: {
        name: false,
      },
      stateEditForm: {
        stateKey: null,
        name: null,
      },
      stateEditFormErr: {
        name: false,
      },
      paging: {
        pageIndex: 0,
        totalPages: 0,
        pageSize: 0,
        totalCount: 0,
        hasPreviousPage: false,
        hasNextPage: false,
      },
      fetchModel: {
        pageIndex: 1,
        pageSize: 10,
        name: null,
      },
      // End State
    };
  },
  methods: {
    async getStates() {
      this.isLoading = true;
      try {
        const res = await stateService.getStates(this.fetchModel);
        this.states = res.data.data.items;
        this.paging = res.data.data;
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
      }
    },
    changeLength(e) {
      const { value } = e.target;
      this.fetchModel.pageSize = value;
      this.getStates();
    },
    getStateByKey(key) {
      try {
        this.isLoading = true;
        stateService.getStateByKey(key).then((res) => {
          this.stateEditForm = res.data.data;
          this.isLoading = false;
        });
      } catch (e) {
        this.isLoading = false;
        const error = e.response.data.message;
        this.alertError(error);
      }
    },
    editState(stateKey) {
      try {
        this.clearStateEditForm();
        this.getStateByKey(stateKey);
      } catch (e) {
        this.isLoading = false;
        const error = e.response.data.message;
        this.alertError(error);
      }
    },
    saveStateForm: async function (e) {
      e.preventDefault();
      if (this.validateStateForm()) {
        try {
          this.isSaving = true;
          await stateService.create(this.stateForm);
          this.isSaving = false;
          document.getElementById("newStateModalClose").click();
          this.clearStateForm();
          this.getStates();
          this.alertSuccess("State added.");
        } catch (e) {
          this.isSaving = false;
          const error = e.response.data.message;
          this.alertError(error);
        }
      } else {
        this.alertWarning("Some form fields are required.");
      }
    },
    saveStateEditForm: async function (e) {
      e.preventDefault();
      if (this.validateStateEditForm()) {
        try {
          this.isSaving = true;
          await stateService.update(
            this.stateEditForm.stateKey,
            this.stateEditForm
          );
          this.isSaving = false;
          document.getElementById("editStateModalClose").click();
          this.clearStateEditForm();
          this.getStates();
          this.alertSuccess("State updated.");
        } catch (e) {
          this.isSaving = false;
          const error = e.response.data.message;
          this.alertError(error);
        }
      } else {
        this.alertWarning("Some form fields are required.");
      }
    },
    openDeleteConfirmation(state) {
      this.stateToDelete = state;
    },
    async deleteState() {
      if (this.stateToDelete != null) {
        this.isDeleting = true;
        try {
          await stateService.delete(this.stateToDelete.stateKey);
          this.stateToDelete = null;
          document.getElementById("deleteCloseButton").click();
          this.alertSuccess("State deleted");
          this.isDeleting = false;
          this.getStates();
        } catch (e) {
          this.isDeleting = false;
          const error = e.response.data.message || "Something went wrong";
          this.alertError(error);
        }
      }
    },

    validateStateForm() {
      this.clearStateValidation();
      if (this.stateForm.name) {
        return true;
      }

      if (!this.stateForm.name) {
        this.stateFormErr.name = true;
      }
      return false;
    },
    clearStateForm() {
      this.stateForm = {
        name: null,
      };
    },
    clearStateValidation() {
      this.stateFormErr = {
        name: false,
      };
    },

    validateStateEditForm() {
      this.clearStateEditValidation();
      if (this.stateEditForm.name) {
        return true;
      }

      if (!this.stateEditForm.name) {
        this.stateEditFormErr.name = true;
      }
      return false;
    },
    clearStateEditForm() {
      this.stateFormEdit = {
        stateKey: null,
        name: null,
      };
    },
    clearStateEditValidation() {
      this.stateEditFormErr = {
        name: false,
      };
    },

    clearPaging() {
      this.paging = {
        pageIndex: 0,
        totalPages: 0,
        pageSize: 0,
        totalCount: 0,
        hasPreviousPage: false,
        hasNextPage: false,
      };
    },
    changePage(pageNum) {
      this.fetchModel.pageIndex = pageNum;
      this.getStates();
    },
    stepPage(direction) {
      if (direction == "next") {
        this.fetchModel.pageIndex = this.paging.pageIndex + 1;
      }
      if (direction == "previous") {
        this.fetchModel.pageIndex = this.paging.pageIndex - 1;
      }
      this.getStates();
    },
    getDate(s) {
      try {
        let d = new Date(s);
        const yyyy = `${d.getFullYear()}`;
        const mm = `0${d.getMonth() + 1}`.slice(-2);
        const dd = `0${d.getDate()}`.slice(-2);
        return `${yyyy}-${mm}-${dd}`;
      } catch (error) {
        return false;
      }
    },
    alertSuccess(description) {
      this.alert.status = "success";
      this.alert.title = "Success";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
    alertError(description) {
      this.alert.status = "fail";
      this.alert.title = "Error";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
    alertWarning(description) {
      this.alert.status = "warn";
      this.alert.title = "Warning";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
  },
  async created() {
    this.getStates();
  },
  filters: {
    formatDatePipe: function (date) {
      return moment(date, "YYYY-MM-DD").format("DD-MM-YYYY : HH:MM");
    },
    formatShortDatePipe: function (date) {
      return moment(date, "YYYY-MM-DD").format("DD-MMM-YYYY");
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
    userCanCreateState() {
      const State = this.user.scopes.find(
        (item) => item.scopeName === Role.GeneralSetup
      );
      const { permissionsList } = State;
      return permissionsList.CanCreateState;
    },
    userCanEditState() {
      const State = this.user.scopes.find(
        (item) => item.scopeName === Role.GeneralSetup
      );
      const { permissionsList } = State;
      return permissionsList.CanEditState;
    },
    userCanDeleteState() {
      const State = this.user.scopes.find(
        (item) => item.scopeName === Role.GeneralSetup
      );
      const { permissionsList } = State;
      return permissionsList.CanDeleteState;
    },
  },
};
</script>
